<template>
  <div class="qy">
    <div class="banner">
      <van-image class="image" :src="require('@/assets/error.png')"></van-image>
    </div>
    <van-cell-group class="order" inset>
      <h2 class="couponType">{{ msg }}</h2>
      <van-divider>温馨提示</van-divider>
      <div style="height: auto">
        <van-cell>
          <div class="foot_tip">
            <p>
              1、充值到账时间一般在24小时以内，若长时间未到账请联系客服；
            </p>
            <p>
              2、客服电话：<a href="tel:4006-659-619">4006-659-619</a>（09:00-18:00）。
            </p>
          </div>
        </van-cell>
      </div>
    </van-cell-group>
  </div>
</template>
<script>
import { getUrlParam } from "../utils";
export default {
  components: {},
  data() {
    return {
      msg: "",
    };
  },
  created() {
    let url = decodeURIComponent(window.location);
    this.msg = getUrlParam(url, "msg");
  },
  methods: {},
};
</script>

<style scoped>
.qy {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #efefef;
  height: 100%;
  position: relative;
  overflow: auto;
}
.banner {
  background: linear-gradient(to bottom, #fb8777, #e77638);
  height: 22em;
  width: 100%;
  border-radius: 0 0 6em 6em;
  position: relative;
  display: flex;
  justify-content: center;
}
.order {
  position: absolute;
  /* bottom: 5em; */
  background: #fff;
  padding: 2em 1em 1em 1em;
  top: 17em;
  height: auto;
  width: 80%;
}

.image {
  position: absolute;
  bottom: 40px;
  z-index: 999;
}
.couponType {
  text-align: center;
  color: red;
  margin: 1.5em 0;
}

.foot_tip {
  font-size: 0.9em;
  color: #969799;
}
</style>
